<template>
  <section class="section section-standalone" id="program-juara">
    <div class="container-fluid my-3">
      <div class="c-dashboard-title d-flex justify-content-between pl-2">
        <h1 class="title-font text-size-title text-uppercase mb-0">
          Program Juara
        </h1>
        <div class="c-dashboard-title-option d-flex align-items-center">
          <select
            class="form-control form-control-sm mr-2 form-control-custom"
            v-model="params.tahun"
            v-on:change="tahunChange()"
          >
            <option value="">Selama Program Berjalan</option>
            <option :value="item" v-for="item in array_tahun" :key="item">
              {{ item }}
            </option>
            <option value="2018">2018</option>
          </select>
        </div>
      </div>
      <div class="grid-dual-column">
        <div class="grid-dual-column-item-1">
          <div class="bg-white py-2 px-3 border rounded  overflow-hidden">
            <div class="p-3">
              <h4
                class="d-flex align-items-center justify-content-between mb-0 text-size-large titilium-font font-weight-normal"
              >
                JUMLAH TERLAKSANA
              </h4>
            </div>
            <div class="chart-doughnut--wrapper mb-4">
              <canvas id="program-chart" width="400" height="200"></canvas>
              <div class="chart-doughnut--text">
                <h2 class="mb-1 text-size-semi-large secondary-font">
                  {{ program.length }}
                </h2>
                <h6
                  class="mb-0 text-uppercase text-size-small font-weight-bold secondary-font"
                >
                  Program
                </h6>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-md-6">
                <div class="bg-yellow-new py-2 px-3 rounded mb-3">
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <div class="flex-row c-list-item--warning">
                      <span
                        class="rounded-circle border p-1 c-list-indicator c-list-indicator--lg mr-2"
                      >
                        <i class="fas fa-circle fa-lg"></i>
                      </span>
                    </div>
                    <div class="flex-row text-right">
                      <h4
                        class="text-warning font-weight-bold d-flex align-items-center justify-content-end"
                      >
                        {{ in_progress }}
                      </h4>
                      <p
                        class="mb-0 text-size-small secondary-font text-uppercase font-weight-normal"
                      >
                        IN PROGRESS
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="bg-green-new py-2 px-3 rounded mb-3">
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <div class="flex-row c-list-item--success">
                      <span
                        class="rounded-circle border p-1 c-list-indicator c-list-indicator--lg mr-2"
                      >
                        <i class="fas fa-check"></i>
                      </span>
                    </div>
                    <div class="flex-row text-right">
                      <h4
                        class="text-green-new font-weight-normal d-flex align-items-center justify-content-end"
                      >
                        {{ done }}
                      </h4>
                      <p
                        class="mb-0 text-size-small secondary-font text-uppercase font-weight-normal"
                      >
                        DONE
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid-dual-column-item-2">
          <div
            class="bg-white py-2 px-3 border rounded mb-3 overflow-hidden"
            style="min-height:500px;margin-right: 20px;"
          >
            <div class="p-3">
              <h4
                class="d-flex align-items-center justify-content-between mb-0 text-size-large titilium-font font-weight-normal"
              >
                Progress per Program
              </h4>
            </div>
            <ul class="nav nav-tabs nav-content" id="myTab" role="tablist">
              <!-- <li class="nav-item">
                            <a class="nav-link " id="jumlah-skpd-tab" data-toggle="tab" href="#jumlah-skpd"
                                role="tab" aria-controls="jumlah-skpd" aria-selected="true">
                                JUMLAH
                            </a>
                        </li> -->
              <li class="nav-item " style="width: 100%;">
                <a
                  class="nav-link active"
                  id="presentase-skpd-tab"
                  data-toggle="tab"
                  href="#presentase-skpd"
                  role="tab"
                  aria-controls="presentase-skpd"
                  aria-selected="false"
                >
                  PERSENTASE
                </a>
              </li>
            </ul>
            <div class="tab-content tab-content-tabs" id="myTabContent">
              <!-- <div class="tab-pane fade show active" id="jumlah-skpd" role="tabpanel"
                            aria-labelledby="profile-tab">
                            <div class="card-dashboard-content p-3 contain" >
                                <div class="d-flex align-items-center mb-2" v-for="(item,key) in listProgram" :key="key">
                                    <div class="flex-row mr-3">
                                        <img :src="item.logo" alt="" class="img-fluid" width="50">
                                    </div>
                                    <div class="flex-row w-100">
                                        <div
                                            class="progress-lable d-flex align-items-center justify-content-between mb-1">
                                            <p class="mb-0 text-capitalize text-body text-size-normal">{{ item.nama_program }}</p>
                                            <span
                                                class="badge bg-gray text-white font-weight-normal d-block p-1 pl-2 pr-2 rounded-lg">{{ formatPrice(item.realisasi) }}</span>
                                        </div>
                                        <div class="progress" style="height: 6px;">
                                            <div class="progress-bar bg-success rounded-right" role="progressbar"
                                                :style="`width: ${item.done}%`" aria-valuenow="30" aria-valuemin="0"
                                                aria-valuemax="100" >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="loadmore mt-3 d-flex justify-content-center">
                                    <button class="btn btn-loadmore" v-on:click="limitChange()" v-if="listProgram.length > 0 && limit < program.length">
                                        LOAD MORE
                                    </button>
                                </div>
                            </div>
                        </div> -->
              <div
                class="tab-pane fade show active"
                id="presentase-skpd"
                role="tabpanel"
                aria-labelledby="contact-tab"
              >
                <div class="card-dashboard-content p-3 mb-3 ">
                  <div
                    class="d-flex align-items-center mb-2 pointer"
                    v-for="(item, key) in listProgram"
                    :key="key"
                    v-on:click="PopUpProgramJuara(item)"
                    data-toggle="modal"
                    data-target="#modalProgram"
                  >
                    <div class="flex-row mr-3">
                      <img
                        :src="item.logo"
                        alt=""
                        class="img-fluid"
                        width="50"
                      />
                    </div>
                    <div class="flex-row w-100">
                      <div
                        class="progress-lable d-flex align-items-center justify-content-between mb-1"
                      >
                        <p
                          class="mb-0 text-capitalize text-body text-size-normal"
                        >
                          {{ item.nama_program | capital }}
                        </p>
                        <span
                          class="badge bg-gray text-white font-weight-normal d-block p-1 pl-2 pr-2 rounded-lg"
                          >{{ parseFloat(item.done).toFixed(2) }}%</span
                        >
                      </div>
                      <div class="progress" style="height: 6px;">
                        <div
                          class="progress-bar bg-success rounded-right"
                          role="progressbar"
                          :style="`width: ${item.done}%`"
                          aria-valuenow="30"
                          aria-valuemin="0"
                          aria-valuemax="100"
                        ></div>
                      </div>
                    </div>
                  </div>
                  <div class="loadmore mt-3 d-flex justify-content-center">
                    <button
                      class="btn btn-loadmore"
                      v-on:click="limitChange()"
                      v-if="listProgram.length > 0 && limit < program.length"
                    >
                      LOAD MORE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="row sumber-data"
        v-if="sumber_data != null"
        v-tooltip.top-start="{
          content: `Pic : ${
            sumber_data.pic == null ? '-' : sumber_data.pic
          } <br> 
                              Kontak : ${
                                sumber_data.kontak == null
                                  ? '-'
                                  : sumber_data.kontak
                              }`,
          show: sumberTooltip,
          trigger: 'manual'
        }"
        style="cursor:pointer"
        v-on:mouseover="changeSumberTooltip"
        v-on:click="changeSumberTooltip"
      >
        <div class="col-md-12 ">
          <p class="text-size-normal ">
            &nbsp; Sumber Data : {{ sumber_data.sumber }} <br />
            &nbsp; Pembaharuan Terakhir :
            {{ sumber_data.updated_at == null ? '-' : sumber_data.updated_at }}
          </p>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="modalProgram"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalIkpTitle"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-custom border-0 rounded-lg modal-dialog-centered"
        role="document"
      >
        <div class="modal-content">
          <div class="modal-header border-0 p-0">
            <button
              type="button"
              class="close rounded-circle bg-white"
              data-dismiss="modal"
              aria-label="Close"
            >
              <i class="fas fa-times fa-xs text-body" aria-hidden="true"></i>
            </button>
          </div>
          <div class="modal-body" v-if="modal">
            <div class="row">
              <div class="col-sm-12">
                <h4>{{ PopUpProgram.nama_program }}</h4>
                <p>{{ PopUpProgram.nama_lain }}</p>
              </div>
              <div class="col-md-6">
                <div class="chart-doughnut--wrapper">
                  <canvas id="modal-chart" width="400"></canvas>
                  <div class="chart-doughnut--text">
                    <h3
                      class="mb-1 secondary-font text-size-large font-weight-bold"
                    >
                      {{ PopUpProgram.done.toFixed(2) }}%
                    </h3>
                    <h6
                      class="mb-0 secondary-font text-uppercase text-size-small font-weight-normal"
                    >
                      Selesai
                    </h6>
                  </div>
                </div>
              </div>
              <div class="col-md-6 mt-4">
                <div class="card p-2 mb-2" style="background-color:#0dc240">
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <div class="flex-row">
                      <img
                        src="@/assets/img/icon-strategi/building.png"
                        alt=""
                        width="40"
                      />
                    </div>
                    <div class="flex-row text-right">
                      <h4
                        class="font-weight-bold text-size-large mb-1 d-flex align-items-center justify-content-end"
                      >
                        {{ PopUpProgram.done.toFixed(2) }}%
                      </h4>
                      <p
                        class="mb-0 text-size-mini text-uppercase font-weight-normal"
                      >
                        Selesai
                      </p>
                    </div>
                  </div>
                </div>
                <div class="card  p-2 mb-2" style="background-color:#ff9a08">
                  <div
                    class="d-flex align-items-center justify-content-between"
                  >
                    <div class="flex-row">
                      <img
                        src="@/assets/img/icon-strategi/building.png"
                        alt=""
                        width="40"
                      />
                    </div>
                    <div class="flex-row text-right">
                      <h4
                        class="font-weight-bold text-size-large mb-1 d-flex align-items-center justify-content-end"
                      >
                        {{ PopUpProgram.progress.toFixed(2) }}%
                      </h4>
                      <p
                        class="mb-0 text-size-mini text-uppercase font-weight-normal"
                      >
                        Progress
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-12">
                <div class="chart-doughnut--wrapper">
                  <canvas id="modal-chart-bar" width="400"></canvas>
                </div>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-12">
                <p>
                  <strong> OPD </strong> <br />
                  {{ PopUpProgram.pelaksana }}
                </p>
              </div>
              <div class="col-sm-6">
                <p>
                  <strong> PIC ASN </strong> <br />
                  <span
                    class="mb-2"
                    v-for="(item, key) in PopUpProgram.pic_asn.arr"
                    :key="key"
                    >{{ item.value != '' ? item.value : '-' }} <br
                  /></span>
                </p>
              </div>
              <div class="col-sm-6">
                <p>
                  <strong> PIC Non ASN </strong> <br />
                  <span
                    class="mb-2"
                    v-for="(item, key) in PopUpProgram.pic_non_asn.arr"
                    :key="key"
                    >{{ item.value != '' ? item.value : '-' }} <br
                  /></span>
                </p>
              </div>
              <div class="col-sm-12">
                <p>
                  <strong> TAJJ </strong> <br />
                  <span
                    class="mb-2"
                    v-for="(item, key) in PopUpProgram.tajj.arr"
                    :key="key"
                    >{{ item.value != '' ? item.value : '-' }} <br
                  /></span>
                </p>
              </div>
              <div class="col-sm-12">
                <p>
                  <strong> Fasilitator </strong> <br />
                  <span
                    class="mb-2"
                    v-for="(item, key) in PopUpProgram.fasilitator.arr"
                    :key="key"
                    >{{ item.value != '' ? item.value : '-' }} <br
                  /></span>
                </p>
              </div>
              <div class="col-sm-12">
                <p>
                  <strong> Mitra Kolaborasi </strong> <br />
                  <span
                    class="mb-2"
                    v-for="(item, key) in PopUpProgram.mitra.arr"
                    :key="key"
                    >{{ item.value != '' ? item.value : '-' }} <br
                  /></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// let date = new Date(window.date_now);
export default {
  metaInfo() {
    return {
      title: 'Program Juara'
    };
  },
  data() {
    return {
      sumberTooltip: false,
      sumber_data: null,
      chart: {
        datasets: [
          {
            data: [4, 3],
            backgroundColor: ['#ff9a08', '#0dc240']
          }
        ],
        type: 'doughnut',
        options: {
          legend: {
            display: false
          },
          cutoutPercentage: 60,
          responsive: true,
          maintainAspectRatio: false
        }
      },
      program: [],
      in_progress: 0,
      done: 0,
      limit: 10,
      PopUpProgram: {
        chart: {
          datasets: [
            {
              data: [4, 3],
              backgroundColor: ['#ff9a08', '#0dc240']
            }
          ],
          type: 'doughnut',
          options: {
            legend: {
              display: false
            },
            cutoutPercentage: 60,
            responsive: true,
            maintainAspectRatio: false
          }
        },
        chartBar: {
          type: 'bar',
          data: {
            labels: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'Mei',
              'Jun',
              'Jul',
              'Ags',
              'Sep',
              'Okt',
              'Nov',
              'Des'
            ],
            datasets: [
              {
                fill: false,
                borderWidth: 4,
                data: [
                  50,
                  20,
                  50,
                  90,
                  50,
                  100,
                  80,
                  100,
                  140,
                  130,
                  150,
                  150,
                  170
                ],
                lineTension: 0,
                backgroundColor: '#EB7467',
                borderColor: '#EB7467'
              }
            ]
          },
          options: {
            bezierCurve: false,
            legend: {
              display: false,
              labels: {
                fontSize: 0
              }
            },
            responsive: true,
            tooltips: {
              mode: 'index',
              callbacks: {
                // Use the footer callback to display the sum of the items showing in the tooltip
                label: function(tooltipItems) {
                  return parseFloat(tooltipItems.yLabel).toFixed(2) + '%';
                }
              }
            },
            hover: {
              mode: 'nearest',
              intersect: true,
              animationDuration: 1
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    display: false //this will remove only the label
                  }
                }
              ],
              yAxes: [
                {
                  ticks: {
                    min: 0,
                    max: 100
                  }
                }
              ]
            },
            animation: {
              duration: 1,
              onComplete: function() {
                var chartInstance = this.chart,
                  ctx = chartInstance.ctx;
                ctx.textAlign = 'center';
                ctx.fillStyle = 'rgba(0, 0, 0, 1)';
                ctx.textBaseline = 'bottom';

                this.data.datasets.forEach(function(dataset, i) {
                  var meta = chartInstance.controller.getDatasetMeta(i);
                  meta.data.forEach(function(bar, index) {
                    var data = dataset.data[index];
                    data = parseFloat(data).toFixed(2);

                    ctx.fillText(data, bar._model.x, bar._model.y - 5);
                  });
                });
              }
            }
          }
        },
        nama_program: '',
        nama_lain: '',
        pelaksana: '',
        pic_asn: [],
        pic_non_asn: [],
        tajj: [],
        fasilitator: [],
        mitra: [],
        done: 0,
        logo: '',
        progress: 0
      },
      modal: false,
      params: {
        tahun: ''
      },
      array_tahun: window.date_years
    };
  },
  mounted() {
    this.loadChart();
  },
  methods: {
    changeSumberTooltip() {
      this.sumberTooltip = true;
      setTimeout(() => {
        this.sumberTooltip = false;
      }, 3000);
    },
    loadChart() {
      window.axios
        .post(
          window.base_api + 'page/programJuara',
          this.params.tahun != '' ? this.params : null
        )
        .then(response => {
          this.sumber_data = response.data.sumber_data;
          this.program = response.data.program;
          if (this.program.length > 0) {
            this.program.forEach(item => {
              if (parseInt(item.target) == 0) {
                this.in_progress++;
              } else if (parseInt(item.realisasi) < parseInt(item.target)) {
                this.in_progress++;
              }
              if (
                parseInt(item.target) != 0 &&
                parseInt(item.realisasi) != 0 &&
                parseInt(item.realisasi) >= parseInt(item.target)
              ) {
                this.done++;
              }
            });
            this.program = this.program.sort(this.compare);
          } else {
            this.in_progress = 0;
            this.done = 0;
          }
          this.chart.datasets[0].data = [
            parseInt(this.in_progress),
            parseInt(this.done)
          ];
          this.setChart();
        });
    },
    setChart() {
      var ctx = document.getElementById('program-chart').getContext('2d');
      let chartData = {
        datasets: this.chart.datasets
      };

      new window.Chart(ctx, {
        type: this.chart.type,
        data: chartData,
        options: this.chart.options
      });
    },
    setChartPopUp() {
      var ctx = document.getElementById('modal-chart').getContext('2d');
      let chartData = {
        datasets: this.PopUpProgram.chart.datasets
      };

      new window.Chart(ctx, {
        type: this.PopUpProgram.chart.type,
        data: chartData,
        options: this.PopUpProgram.chart.options
      });

      ctx = document.getElementById('modal-chart-bar').getContext('2d');
      chartData = {
        datasets: this.PopUpProgram.chartBar.data.datasets,
        labels: this.PopUpProgram.chartBar.data.labels
      };

      new window.Chart(ctx, {
        type: this.PopUpProgram.chartBar.type,
        data: chartData,
        options: this.PopUpProgram.chartBar.options
      });
    },
    tahunChange() {
      this.in_progress = 0;
      this.done = 0;
      this.loadChart();
      this.limit = 10;
    },
    limitChange() {
      this.limit += 5;
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace('.', ',');
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    compare(a, b) {
      a = a.done;
      b = b.done;

      let comparison = 0;
      if (a > b) {
        comparison = 1;
      } else if (a < b) {
        comparison = -1;
      }
      return comparison * -1;
    },
    PopUpProgramJuara(item) {
      var labels = item.chart.map(arr => {
        return arr.label;
      });
      var total = item.chart.map(arr => {
        return arr.total;
      });

      setTimeout(() => {
        window.$('#modalProgram').on('shown.bs.modal', () => {
          this.modal = true;
        });

        window.$('#modalProgram').on('hidden.bs.modal', () => {
          this.modal = false;
        });
      }, 100);
      this.PopUpProgram.chartBar.data.datasets[0].data = total;
      this.PopUpProgram.chartBar.data.labels = labels;
      this.PopUpProgram.chart.datasets[0].data = [item.progress, item.done];
      this.PopUpProgram.nama_program = item.nama_program;
      this.PopUpProgram.nama_lain = item.nama_lain;
      this.PopUpProgram.logo = item.logo;
      this.PopUpProgram.pelaksana = item.pelaksana;
      this.PopUpProgram.done = item.done;
      this.PopUpProgram.progress = item.progress;

      this.PopUpProgram.pic_asn = item.pic_asn;
      this.PopUpProgram.pic_non_asn = item.pic_non_asn;
      this.PopUpProgram.tajj = item.tajj;
      this.PopUpProgram.fasilitator = item.fasilitator;
      this.PopUpProgram.mitra = item.mitra;
      this.modal = true;
      setTimeout(() => {
        this.setChartPopUp();
      }, 200);
    }
  },
  computed: {
    listProgram() {
      return this.program.slice(0, this.limit).sort(this.compare);
    }
  },
  filters: {
    capital(val) {
      return val.toUpperCase();
    }
  }
};
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>
